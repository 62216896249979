import React, { useState } from "react"
import List from './List'
import Arrow from '../assets/arrow.png'

const Accordion = ({ data, className }) => {
    const [selectedItem, setSelectedItem] = useState(null)
    const handleClick = (i) => {
        if (i === selectedItem)
            setSelectedItem(null) 
        else 
            setSelectedItem(i)
    }

    return (
        <div className={`accordion-wrapper ${className}`}>
            {data.map((data, i) => 
                <div key={i} className={`accordion accordion${i+1}`}>
                    <div onClick={() => handleClick(i)} className={`acc-title accordion-title-${i+1} ${selectedItem === i ? "selected" : ""}`}>
                        <h5 className="h5">{data.title}</h5>
                        <img src={Arrow} alt="" className={selectedItem === i ? "selected" : ""} />
                    </div>
                    <div className={`acc-description accordion-description-${i+1} ${selectedItem === i ? "selected show" : "hide"}`}>
                        {data.bulletPoints.length < 2 ? 
                        <p className="main-bodycopy">{data.bulletPoints}</p> :
                        <List bulletPoints={data.bulletPoints} />}
                    </div>
                </div>
            )}
        </div>
    )
}
  
export default Accordion