import React, { useState } from "react"
import List from '../../ui/List'
import Arrow from '../../assets/arrow.png'
import Accordion from "../../ui/Accordion"

const requirements = [
  {
    title: "Founder requirements",
    bulletPoints: [
      "A founding member of your team (>10% equity) must be woman-identifying, whether cis-or-transgender, or non-binary ",
      "Consideration will also be given to underrepresented racial or ethnic groups, people with disabilities, and other historically underrepresented individuals",
    ]
  },
  {
    title: "Mission-driven organization",
    bulletPoints: [
      "Your startup is actively working to address a public health disparity for the women+ audience",
    ]
  },
  {
    title: "In-market solution",
    bulletPoints: [
      "Advanced in product offering with an in-market solution,revenue, and customer base",
      "Must be willing to state marketing budget that can be used for activation",
    ]
  },
  {
    title: "Digital health product or service",
    bulletPoints: [
      "Desire to utilize CX, data, and technology to personalize an outcomes-driven solution for people with underserved health needs",
    ]
  },
  {
    title: "Supported sectors",
    bulletPoints: [
      "Pregnancy, postpartum, and parenthood support",
      "Primary and preventive care",
      "Fertility support",
      "Sexual and reproductive care",
      "Chronic disease care",
      "Perimenopause and menopause care",
      "Menstruation care",
      "Behavioral health",
    ]
  }
]

const QualifierItem = ({ className }) => {
    const [selectedButton, setSelectedButton] = useState(0)
    return (
        <>
            <div className={`qualifier-content-wrapper ${className}`}>
                <div className="title-wrapper">
                    {requirements.map((data, i) => 
                        <div key={i} onClick={() => {setSelectedButton(i)}} className={`title-${i+1} ${selectedButton === i ? "selected" : ""}`}>
                            <h5 className="h5">{data.title}</h5>
                            <img src={Arrow} alt="" />
                        </div>)}
                </div>
                <div className="description-wrapper">
                    {
                        (requirements[selectedButton].bulletPoints).length < 2 ? 
                        <p className="main-bodycopy">{(requirements[selectedButton].bulletPoints)}</p> :
                        (requirements[selectedButton].bulletPoints).length > 4  ?
                        <>
                            <List bulletPoints={(requirements[selectedButton].bulletPoints).slice(0, 4)} className="first-half" />
                            <List bulletPoints={(requirements[selectedButton].bulletPoints).slice(4)} className="second-half" />
                        </> :
                        <List bulletPoints={requirements[selectedButton].bulletPoints} />
                    }
                </div>
            </div>
            <Accordion data={requirements} className="hide-desktop" />
        </>
    )
}

export default QualifierItem