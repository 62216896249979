import '../../App.css'
import ArticleIconHeads from '../../assets/icon-article-heads.svg'
import ArticleIconPress from '../../assets/icon-article-press.svg'

const articleData = [
    {
      text: "Welcoming the Future of Femtech with RAPP Series R",
      icon: ArticleIconHeads,
      type: "IN OUR HEADS",
      href: "https://www.rapp.com/news/in-our-heads/welcoming-the-future-of-femtech-with-rapp-series-r/"
    },
    {
      text: "RAPP launches program to mentor femtech health equity startup",
      icon: ArticleIconPress,
      type: "In the press",
      href: "https://www.rapp.com/news/in-the-press/rapp-launches-program-to-mentor-femtech-health-equity-startup/"
    }
    

]

const ArticleItems = () => {
 
  
    return (
      <>
      <div className="articles-content-wrapper">
        {articleData.map((article) => 
          <a href={article.href} class="article" target='_blank'>
            <div class='article-type'>
              <img class='icon' src={article.icon} /> <span class='type'>{article.type}</span>
            </div>
            <span class='text'>{article.text}</span>
          </a>
        )}
      </div>
      </>
    )
    
}

export default ArticleItems