const List = ({ bulletPoints, className }) => {
    return (
        <ul className={className}>
            {bulletPoints.map((bullet, i) => 
            <li key={i} className={`bullet-${i+1} main-bodycopy`}>{bullet}</li>
            )}
        </ul>
    )
}

export default List