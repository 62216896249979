import React, { useState } from 'react';

const ApplyNowContext = React.createContext()

// Create a component that will hold the state of the overlay
const OverlayProvider = ({ children }) => {
    const [showOverlay, setShowOverlay] = useState(false);

    return (
      <ApplyNowContext.Provider value={{ showOverlay, setShowOverlay }}>
        {children}
      </ApplyNowContext.Provider>
    );
}

const OverlayConsumer = ApplyNowContext.Consumer

export { OverlayProvider, OverlayConsumer, ApplyNowContext }