const CTAButton = ({ label, href, className, divClass, target }) => {
    const targetDefault = target == null ? "_blank" : target;
    return (
        <div className={`cta-wrapper ${divClass}`}>
            <a href={href} target={targetDefault} rel="noreferrer" className={className}>
                {label}
            </a>
        </div>
    )
}

export default CTAButton