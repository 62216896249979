const Donut = ({ percentage, percentageDesc, className }) => {
    return (
        <div className={`donut-div ${className}`}>
            <div className="donut-chart">
                <div className="inner-circle">
                    <p className="percentage">{percentage}<sup>%</sup></p>
                    <p className="desc">{percentageDesc}</p>        
                </div>
            </div>
        </div>
    )
}

export default Donut