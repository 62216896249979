import React, { useContext } from 'react'
import { ApplyNowContext } from "../context/ApplyNowContext"


// I added the className on a tag to be "inactive" but when ready it should just go back to {className}
const ApplyButton = ({ label, className, divClass }) => {
    const { setShowOverlay } = useContext(ApplyNowContext)
    return (
        <div className={`cta-wrapper ${divClass}`}>
            <div onClick={() => setShowOverlay(true)} className={`${className}`}>
                {label}
            </div>
        </div>
    )
}

export default ApplyButton