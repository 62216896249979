import React, { useContext, useEffect } from 'react'
import './App.css'
import Header from './components/Header'
import About from './components/About'
import DataPoints from './components/DataPoints'
import Benefits from './components/Benefits'
import Qualifiers from './components/Qualifiers'
import Process from './components/Process'
import Judges from './components/Judges'
import Articles from './components/Articles'
import HowToApply from './components/HowToApply'
import Connect from './components/Connect'
import ContestRules from './components/ContestRules'
import Footer from './components/Footer'
import StickyBannerCTA from './components/StickyBannerCTA'
import DownloadPdfOverlay from './components/DownloadPdfOverlay'
import FloatingImg from './ui/FloatingImg'
import GraphicBeams from './assets/graphic-beams.png'
import { ApplyNowContext } from './context/ApplyNowContext'

const sectionsArr = ["about", "benefits", "qualifiers", "process", "judges", "how to apply", "connect", "contest rules"]
const rappColorsArr = ["orange", "red", "yellow", "green", "blue"]

function App() {
  const { showOverlay } = useContext(ApplyNowContext)

  useEffect(() => {
    const nodes = document.getElementsByTagName('body')
    if (!nodes.length) return
    
    nodes[0].style.overflow = showOverlay ? 'hidden' : 'auto'
  }, [showOverlay])
  return (
      <div className={`App ${showOverlay ? 'of-hidden' : ''}`}>
        <Header menuOptions={sectionsArr} colors={rappColorsArr} />
        <div className="content-wrapper">
          <About id={sectionsArr[0]} className={`${sectionsArr[0]}-section`} />
          <DataPoints />
          <FloatingImg src={GraphicBeams} className="graphic-beams" alt="" divClass="graphic-beams-wrapper show-desktop" />
          <Benefits id={sectionsArr[1]} className={`${sectionsArr[1]}-section`} />
          <Qualifiers id={sectionsArr[2]} className={`${sectionsArr[2]}-section semi-circle-r`} />
          <Process id={sectionsArr[3]} className={`${sectionsArr[3]}-section`} />
          <Judges id={sectionsArr[4]} className={`${sectionsArr[4]}-section semi-circle-l`} />
          <HowToApply id={sectionsArr[5].replace(/\s/g, '')} className={`${sectionsArr[5].replace(/\s/g, '')}-section`} />
          <Articles className={`articles-section semi-circle-r`} />
          <Connect id={sectionsArr[6]} className={`${sectionsArr[6]}-section`} />
          <ContestRules id={sectionsArr[7].replace(/\s/g, '')} className={`${sectionsArr[7].replace(/\s/g, '')}-section`} />
          <Footer id="footer" className="footer-section" />
        </div>
        <StickyBannerCTA />
        { showOverlay && <DownloadPdfOverlay /> }
      </div>
  );
}

export default App;