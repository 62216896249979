import '../../App.css'
import H2 from '../../ui/H2'
import BenefitItem from './BenefitItem'
import FloatingImg from '../../ui/FloatingImg'
import HeaderIco from '../../assets/header-ico.png'

const Benefits = ({ id, className }) => (
        <div className={className} id={id}>
            <FloatingImg src={HeaderIco} className="header-icon" alt="" divClass="header-icon-wrapper" />
            <div className="container">
                <div className="header-wrapper">
                    <H2 h2="Take advantage of personalized program benefits" className="h2" />
                    <p className="main-bodycopy">
                        Our comprehensive Series R package includes a pro bono 3-month customized package of marketing services, mentorship, and networking opportunities.
                    </p>
                </div>
                <div className="benefit-content-wrapper">
                    <BenefitItem className />
                </div>
                <p className="last-p t-left semi-bold">
                    Accelerator prize does not include funding for video production, photoshoots, media buying, or other related costs.
                </p>
            </div>
        </div>
)

export default Benefits