import '../../App.css'
import H2 from '../../ui/H2'
import JudgeItem from './JudgeItem'
import FloatingImg from '../../ui/FloatingImg'
import HeaderIco from '../../assets/header-ico.png'

const Judges = ({ id, className }) => (
    <div className={className} id={id}>
        <FloatingImg src={HeaderIco} className="header-icon" alt="" divClass="header-icon-wrapper" />
        <div className="container">
            <div className="header-wrapper">
                <H2 h2="Judges" className="h2" />
            </div>
            <JudgeItem />
        </div>
    </div>
)

export default Judges