import '../../App.css';
import Donut from '../../ui/Donut';
import H2 from '../../ui/H2';

const dataPoints = [
                        {
                            label: "Women spend much more on their health than men",
                            percentage: "80",
                            percentageDesc: "Total consumer spending on health care"
                        },
                        {
                            label: "But FemTech only gets a tiny piece of the pie",
                            percentage: "5",
                            percentageDesc: "invested in FemTech"
                        },
                    ]

const DataPoints = () => (
        <div className="data-point-section" id="data-points">
            <div className="container">
                <div className="header-wrapper">
                    <H2 h2="Break the FemTech funding gap" className="t-center" />
                    <p className="main-bodycopy t-center">
                        Startup founders like you are taking big financial risks as you create new healthcare solutions for underrepresented groups.
                    </p>
                </div>
                <div className="data-point-content-wrapper">
                    {dataPoints.map((data, i) => 
                        <div key={i} className={`content-${i+1}`}>
                            <div className="label-wrapper">
                                <p className={`main-bodycopy t-center semi-bold label-${i+1}`}>{data.label}</p>
                            </div>
                            <div className="donut-wrapper">
                                <Donut key={i} percentage={data.percentage} percentageDesc={data.percentageDesc} className={`donut-${i+1}`} />
                            </div>
                        </div>
                    )}
                </div>
                <p className="last-p main-bodycopy t-center semi-bold">
                    As the agency that stands up for Fierce Individuality, we want to stand up for you.
                </p>
            </div>
        </div>
)

export default DataPoints