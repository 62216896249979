import "../../App.css"
import ApplyButton from "../../ui/ApplyButton"
import CTAButton from "../../ui/CTAButton"

const StickyBannerCTA = () => (
  <div className="sticky-banner-section" id="sticky-banner">
    <div className={`container sticky-banner-wrapper`}>
        <div className="main-bodycopy">
            <p><strong>Yes</strong>, I want to raise the bar with Series R</p>
        </div>
        <ApplyButton className="header-cta inactive" divClass="t-left" label="CONTEST CLOSED" />
        <CTAButton label="Contest Rules" href="#contestrules" className="contest-rules-cta" target="" divClass="t-left"/>
    </div>
  </div>
)

export default StickyBannerCTA;
