import '../../App.css'
import NavList from "../../ui/NavList";
import Facebook from '../../assets/facebook.png'
import Twitter from '../../assets/twitter.png'
import Instagram from '../../assets/instagram.png'
import Linkedin from '../../assets/linkedin.png'
import { toTitleCase } from "../../utils/string-utils"
import useWindowDimensions from '../../utils/get-screen-size-utils'

const footerList = [
    {
        id: "contact",
        copy: "Contact us",
        link: "https://www.rapp.com/contact-us/"
    },
    {
        id: "privacy_policy",
        copy: "Privacy Policy",
        link: "https://www.rapp.com/privacy-policy/"
    },
    {
        id: "gdpr_policy",
        copy: "GDPR Privacy Policy",
        link: "https://www.rapp.com/gdpr-privacy-policy/"
    },
    {
        id: "cookie_policy",
        copy: "Cookie Policy",
        link: "https://www.rapp.com/cookie-policy/"
    }
]

const footerIcons = [
    {
        id: "facebook",
        icon: Facebook,
        link: "https://www.facebook.com/RAPPWORLDWIDE/"
    },
    {
        id: "twitter",
        icon: Twitter,
        link: "https://twitter.com/RAPP"
    },
    {
        id: "instagram",
        icon: Instagram,
        link: "https://www.instagram.com/rappworldwide/"
    },
    {
        id: "linkedin",
        icon: Linkedin,
        link: "https://www.linkedin.com/company/rapp/"
    }
]

const Footer = ({ id, className }) => {
    const {width} = useWindowDimensions()
    return (
        <div className={className} id={id}>
        <div className="container">
            <div className="footer-list nav-wrapper">
                {width < 1025 ? <p><strong>RAPP </strong> is an Omnicom Company.<br /> &copy; {new Date().getFullYear()} RAPP. All rights reserved.</p> : ""}
                <ul>
                    {width > 1025 ? <li>RAPP is an Omnicom Company. &copy; {new Date().getFullYear()} <strong>RAPP</strong> All rights reserved.</li> : ""}
                    {footerList.map((item, i) => <NavList key={i} item={item.copy} className={item.id} href={item.link} target="_blank"/>)}
                </ul>
            </div>
            <div className="footer-icons">
                {footerIcons.map((item, i) => 
                    <a key={i} href={item.link} className={item.id} target="_blank" rel="noreferrer">
                        <img src={item.icon} alt={toTitleCase(item.id)} />
                    </a>
                )}
            </div>
        </div>
    </div>
    )
}

export default Footer