import '../../App.css'
import H2 from '../../ui/H2'
import ProcessItem from './ProcessItem'
import FloatingImg from '../../ui/FloatingImg'
import HeaderIco from '../../assets/header-ico.png'

const Process = ({ id, className }) => (
    <div className={className} id={id}>
        <FloatingImg src={HeaderIco} className="header-icon" alt="" divClass="header-icon-wrapper" />
        <div className="container">
            <div className="header-wrapper">
                <H2 h2="Track your timeline" className="h2" />
            </div>
        </div>
        <div className="line"></div>
        <ProcessItem className=""/>
    </div>
)

export default Process