const IcoWINfo = ({ src, alt, className, label, description, i }) => {
    return (
        <div key={i} className={`ico-info-wrapper ${className}`}>
            <img src={src} alt={alt} />
            <h4 className="ico-label h4">{label}</h4>
            {description != null && description !== "" ? <p className="ico-desc">{description}</p> : ""}
        </div>
    )
}

export default IcoWINfo