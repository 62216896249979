import "../../App.css"
import H2 from "../../ui/H2"
import ApplyButton from "../../ui/ApplyButton"
import IntroBannerDesk from "../../assets/intro-banner.png"
import IntroBannerTab from "../../assets/intro-banner-tab.png"
import IntroBannerMb from "../../assets/intro-banner-mb.png"
import Subhead from "../../ui/Subhead"
import useWindowDimensions from '../../utils/get-screen-size-utils'

const About = ({ id, className }) => {
  const {width} = useWindowDimensions()
  const bannerImg = width < 601 ? IntroBannerMb : width < 1025 ? IntroBannerTab : IntroBannerDesk;
  return (
    <div className={className} id={id}>
      <div className="banner-wrapper">
        <picture>
          <source media="(max-width:600px)" srcSet={IntroBannerMb} />
          <source media="(max-width:1024px)" srcSet={IntroBannerTab} />
          <img src={IntroBannerDesk} className="banner" alt="A female presenter delivering a speech to a captivated audience." />
        </picture>
      </div>
    <div className="container">
      <div className="div1">
        <H2 h2="You've got a FemTech health solution. We can help you market it." className="t-left" />
        <p className="main-bodycopy t-left">
          Today's healthcare system leaves far too many people behind. <br className="hide-mb"/>
          So we are launching our <strong>RAPP Series R Health Equity Marketing Accelerator</strong> with a focus on the emerging field of FemTech.
        </p>
      </div>
      <div className="div2">
        <div className="green-oval">
          <Subhead className="semi-bold" subhead="If you're on a mission to address disparities in women+ health, we want to help you achieve it — faster." />
          <p className="main-bodycopy t-left">Download your application today and submit by 11:59 p.m. EDT on April 30, 2023.</p>
          <ApplyButton className="header-cta inactive" divClass="t-left" label="CONTEST CLOSED" />
        </div>
      </div>
    </div>
  </div>
  )
}

export default About;

