import iconTwitter from "../../assets/twitter-icon.svg"
import iconFacebook from "../../assets/facebook-icon.svg"
import iconLinkedin from "../../assets/linkedin-icon.svg"

const socialShare = {
    FacebookShareLink: "http://www.facebook.com/sharer/sharer.php?u=",
    TwitterShareLink: "http://twitter.com/intent/tweet?text=",
    LinkedInShareLink: "http://www.linkedin.com/shareArticle?url=",
    FacobookAppID: 0x84744a2c7b47,
    popupProperty: function(e, t) {
        var i = void 0 !== window.screenLeft ? window.screenLeft : window.screenX,
            n = void 0 !== window.screenTop ? window.screenTop : window.screenY,
            s = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth,// ? document.documentElement.clientWidth : screen.width,
            r = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight, //? document.documentElement.clientHeight : screen.height,
            o = s / window.screen.availWidth,
            a = (s - e) / 2 / o + i,
            l = (r - t) / 2 / o + n;
        return "\n            width=".concat(e / o, ", \n            height=").concat(t / o, ", \n            top=").concat(l, ", \n            left=").concat(a, ",\n            resizable=yes,\n            scrollbars=yes,\n            toolbar=yes,\n            menubar=no,\n            location=no,\n            directories=no,\n            status=yes\n        ")
    },
    trimText: function(e) {
        return e.replace(/^\s+|\s+$/gm, "").replace(/\s+/g, " ")
    },
    addPlusToEmptySpace: function(e, t) {
        return e.replace(/\s+/g, t)
    },
    socialPopup: function(e) {
        if ("facebook" == e || "twitter" == e || "linkedin" == e) {
            var t = 'http://seriesr.rapp.com',
                i = "";
            if ("facebook" == e)
                i = this.FacebookShareLink + encodeURIComponent(t);
            else if ("twitter" == e) {
                var n = document.querySelector('meta[property="twitter:description"]'),
                    s = null != n ? this.trimText(n.content) : "";
                i = this.TwitterShareLink + encodeURIComponent(s + " " + t)
            } else
                "linkedin" == e && (i = this.LinkedInShareLink + encodeURIComponent(t));
            return window.open(i, "", this.popupProperty(800, 600)), !1
        }
    },
    click: function(element, value) {
        element.preventDefault();
        this.socialPopup(value)
    }
};

const Share = ({ label }) => {
    const shareDefault = label == null ? "SHARE" : label;
    //socialShare.init();
    return (
        <div class={`share-wrapper`}>

                
                <ul>
                    <li>
                        <span>{shareDefault}</span>
                    </li>
                    <li>
                        <a class="js-btn-social facebook" href="#" onClick={ (e) =>{ socialShare.click(e, 'facebook')}} data-share="facebook" >
                          <img src={iconFacebook} alt="facebook"/>
                        </a>
                    </li>
                    <li>
                        <a class="js-btn-social twitter" href="#" onClick={ (e) =>{ socialShare.click(e, 'twitter')}} data-share="twitter">
                          <img src={iconTwitter} alt="twitter"/>
                        </a>
                    </li>
                    <li>
                        <a class="js-btn-social linkedin" href="#" onClick={ (e) =>{ socialShare.click(e, 'linkedin')}} data-share="linkedin">
                          <img src={iconLinkedin} alt="linkedin"/>
                        </a>
                    </li>
                </ul>
        </div>
    )
}

export default Share;