import "../../App.css";
import ApplyButton from "../../ui/ApplyButton";
import H2 from "../../ui/H2";

const HowToApply = ({ id, className }) => (
  <div className={className}>
    <div className="container">
      <div className="yellow-oval" id={id}>
        <div className="header-wrapper">
          <H2 h2="Complete your application" className="h2" />
        </div>
        <ul className="apply-list">
          <li className="main-bodycopy">
            Download and fill out the Series R application with Entry Form
          </li>
          <li className="main-bodycopy">
            Email your completed application with signed Entry Form, pitch deck and marketing plan to <a href="mailto:seriesr@rapp.com">seriesr@rapp.com</a>.
          </li>
        </ul>
        <ApplyButton className="header-cta inactive" divClass="t-left" label="CONTEST CLOSED" />
      </div>
    </div>
  </div>
);

export default HowToApply;
