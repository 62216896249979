import '../../App.css'
import React, { useState } from "react"
import IcoWINfo from '../../ui/IcoWInfo'
import LeftArrow from '../../assets/left-arrow.png'
import RightArrow from '../../assets/right-arrow.png'
import CharlaTriplett from '../../assets/charlatriplett.png'
import TraceyKeim from '../../assets/traceykeim.png'
import EricaKraus from '../../assets/ericakraus.png'
import NickyBattle from '../../assets/nickybattle.png'
import LoreleyRey from '../../assets/loreleyrey.png'
import LaurenPattaniWright from '../../assets/laurenpattaniwright.png'
import useWindowDimensions from '../../utils/get-screen-size-utils'

const judgesData= [
    {
      name: "Charla Triplett",
      title: "Managing Director Suncoast Ventures",
      image: CharlaTriplett,
      alt: ""
    },
    {
      name: "Tracy Keim",
      title: "Chief Marketing Officer Qvin",
      image: TraceyKeim,
      alt: ""
    },
    {
      name: "Erica Kraus",
      title: "SVP, US Health Strategy Lead RAPP",
      image: EricaKraus,
      alt: ""
    },
    {
      name: "Nicky Battle",
      title: "Managing Director, Healthcare Ketchum",
      image: NickyBattle,
      alt: ""
    },
    {
        name: "Loreley Rey",
        title: "Executive Director, Dieste Health",
        image: LoreleyRey,
        alt: ""
    },
    {
        name: "Lauren Pattani-Wright",
        title: "Global Client Lead, Omnicom",
        image: LaurenPattaniWright,
        alt: ""
    }
    

]

const JudgeItem = () => {
  const {width} = useWindowDimensions()
  const [current, setCurrent] = useState(0);
  const isMobile = width < 1025
  const judgeCount = isMobile ? 1 : 3
  const slideNumber = Math.ceil(judgesData.length/judgeCount)
  const nextSlide = () => {
    const availableSlides = slideNumber - 1
    if (current < availableSlides) setCurrent(current + 1)
  }
  const prevSlide = () => {
    if (current > 0) setCurrent(current - 1) 
  }
  const currentJudges = judgesData.slice(judgeCount * current, judgeCount * (current + 1))
  const dots = [];
  for (let i = 0; i < slideNumber; i++) {dots.push(<div key={i} className={current === i ? "current dot" : "dot"}></div>)}
    return (
      <>
      <div className="judges-content-wrapper">
        {currentJudges.map((slide, i) => 
          <IcoWINfo key={i} src={slide.image} alt={slide.alt} label={slide.name} description={slide.title} className="t-center" />
        )}
      </div>
      <div className="clicker">
        <img src={LeftArrow} className={`left-arrow ${current === 0 ? "hide" : ""}`} onClick={prevSlide} alt="Left arrow" />
        {dots}
        <img src={RightArrow} className={`right-arrow ${slideNumber - current === 1 ? "hide" : ""}`} onClick={nextSlide} alt="Left arrow" />
      </div>
      </>
    )
}

export default JudgeItem