const process = [
    {
        date: "March – April",
        process: "Download, complete and return your application"
    },
    {
        date: "May",
        process: "RAPP selects 3 finalists and sends invitations to virtual pitch"
    },
    {
        date: "May",
        process: "Virtual pitch and winner announced"
    },
    {
        date: "June – August",
        process: "Core client engagement"
    },
]
const ProcessItem = ({ className }) => {
    return (
        <div className={`process-content-wrapper ${className}`}>
            {process.map((data, i) => 
                <div key={i} className={`process-${i+1} ${className} semi-circle-r`}>
                    <div className="dot"></div>
                    <h4 className="h4">{data.date}</h4>
                    <p className="main-bodycopy">{data.process}</p>
                </div>)}
        </div>
    )
}


export default ProcessItem