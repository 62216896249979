import "../../App.css";
import ApplyButton from "../../ui/ApplyButton";
import NavList from "../../ui/NavList";
import { ReactComponent as RappLogo } from "../../rapplogo.svg";
import React, { useState, useEffect } from "react";

const Header = ({ menuOptions, colors }) => {
  const [navActive, setNavActive] = useState(false);
  const toggleClass = () => {
    setNavActive(!navActive)
  }
  const toggleSetClass = (option) => {
    setNavActive(!navActive)
    setFocusClass(option)
  }
  const [focusClass, setFocusClass] = useState();
  useEffect(() => {
    const tag = window.location.href.substring(window.location.href.indexOf("#") + 1);
    if (tag.length === 0) return;
    setFocusClass(tag);
  }, []);
  return (
    <div className="header-section" id="header">
      <div className="container">
        <div className="logo-wrapper">
          <a href="https://www.rapp.com"><RappLogo className="logo" /></a>
        </div>
        <div className="nav-wrapper show-desktop">
          <ul>
            {menuOptions.map((option, i) => {
              const focus = option.toLowerCase().replace(/\s/g, "") === focusClass ? "focus" : "";
              return <NavList key={i} item={option} onClick={() => setFocusClass(option.toLowerCase().replace(/\s/g, ""))} className={`${i > 4 ? colors[i - 5] : colors[i]} ${focus}`} href={`#${option.toLowerCase().replace(/\s/g, "")}`} />;
            })}
          </ul>
        </div>
        <div className={`nav-wrapper-mb hide-desktop${navActive ? " open" : ""}`}>
          <ul>
            {menuOptions.map((option, i) => {
                const focus = option.toLowerCase().replace(/\s/g, "") === focusClass ? "focus" : "";
              return <NavList key={i} item={option} onClick={() => toggleSetClass(option.toLowerCase().replace(/\s/g, ""))} className={`${i > 4 ? colors[i - 5] : colors[i]} ${focus}`} href={`#${option.toLowerCase().replace(/\s/g, "")}`} />;
            })}
          </ul>
        </div>
        <div className={`nav-icon-mb hide-desktop${navActive ? " open" : ""}`} onClick={() => toggleClass()}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ApplyButton className="header-cta inactive show-desktop" divClass="" label="CONTEST CLOSED" />
      </div>
    </div>
  );
};

export default Header;
