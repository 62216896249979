import '../../App.css'
import ArticleItems from './ArticleItems'

const Articles = ({ id, className }) => (
    <div className={className} id={id}>
        
        <div className="container">
            <ArticleItems />
        </div>
    </div>
)

export default Articles