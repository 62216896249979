import "../../App.css"
import CTAButton from "../../ui/CTAButton"
import Share from "./Share"
import FloatingImg from "../../ui/FloatingImg"
import HeaderIco from "../../assets/header-ico.png"
import ConnectImg from "../../assets/connect.png"
import GraphicBeams2 from "../../assets/graphic-beams-2.png"
import GraphicBeams2Mb from "../../assets/graphic-beams-2.png"
import useWindowDimensions from '../../utils/get-screen-size-utils'

const Connect = ({ id, className }) => {
  const {width} = useWindowDimensions()
  const graphicBeamsImg = width < 1025 ? GraphicBeams2Mb : GraphicBeams2;
  return (
    <div class={className} id={id}>
      <FloatingImg src={HeaderIco} className="header-icon" alt="" divClass="header-icon-wrapper" />
      <div class="container">
        <div class="header-wrapper">
          <h2 class="h2">Rapp stands up <br />for individuality</h2>
        </div>
        <div class="connect-content-wrapper">
          <div class="left-section">
            <div class="copy-wrapper">
              <p class="main-bodycopy">
                RAPP is a global creative marketing agency that knows how to speak to one individual, 
                a million at a time. In fact, the individual is at the heart of everything we do. 
                And nothing is more individual than our health and wellbeing. We use data to find out 
                what makes each person tick. And we deliver creative, data-driven solutions that encourage 
                your customers to keep coming back, over and over, again and again.
              </p>
            </div>
            <div class="dbl-cta-wrapper">
              <CTAButton label="Learn more" href="https://www.rapp.com/" className="header-cta red-b" divClass="" />
              <CTAButton label="Contact us" href="https://www.rapp.com/contact-us/" className="header-cta red-b" divClass="" />
            </div>
            <Share />
          </div>
          <div className="right-section">
            {/* <img src={ConnectImg} alt="A girl smiling while hugging her beloved dog." /> */}
            <div class="player" style={{padding:'56.25% 0 0 0',position:'relative'}}><iframe src="https://player.vimeo.com/video/821044026?h=6a41e4ec5a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} title="RAPP IWD SeriesR SizzleReel 3.28"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>
      <FloatingImg src={graphicBeamsImg} className="graphic-beams-2" alt="" divClass="graphic-beams-2-wrapper" />
    </div>
  )
}

export default Connect;
