import '../../App.css'
import H2 from '../../ui/H2'
import QualifierItem from './QualifierItem'
import FloatingImg from '../../ui/FloatingImg'
import HeaderIco from '../../assets/header-ico.png'

const Qualifiers = ({ id, className }) => (
    <div className={className} id={id}>
        <FloatingImg src={HeaderIco} className="header-icon" alt="" divClass="header-icon-wrapper" />
        <div className="container">
            <div className="header-wrapper">
                <H2 h2="Check your eligibility" className="h2" />
            </div>
        </div>
        <QualifierItem className="show-desktop" />
        <div className="in-addition">
            <p className="last-p">
                In addition, the startup must be US owned and operated.
            </p>
        </div>
    </div>
)

export default Qualifiers