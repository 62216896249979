import IcoWINfo from '../../ui/IcoWInfo'
import List from '../../ui/List'
import ico1 from '../../assets/Icon_1.png'
import ico2 from '../../assets/Icon_2.png'

const benefitData = [
    {
        ico: ico1,
        icoLabel: "Marketing services",
        alt: "",
        items: [
                {
                    listTitle: "",
                    bulletPoints: [
                        "Consumer definition and Affinity Analysis",
                        "A 3-day ideation workshop to imagine a new experience and define how exactly to drive business growth",                     
                        "A core experience prototype build"
                        ]
                }
        ]
    },
    {
        ico: ico2,
        icoLabel: "Mentorship + Networking",
        alt: "",
        items: [
                {
                    listTitle: "",
                    bulletPoints: [
                        "Fractional CMO, including biweekly office hours for 6 months",
                        "Invitations to RAPP and Omnicom thought leadership and networking events",
                        "New connections with advisors and investors"
                    ]
                }
        ]
    }
]

const BenefitItem = ({ className }) => {
    return (
        benefitData.map((data, i) => 
        <div key={i} className={`benefit-item-${i+1} ${className}`}>
            <IcoWINfo src={data.ico} alt={data.alt} label={data.icoLabel} className="" description="" key={i} />
            <div className="list-wrapper">
                {data.items.map((list, i) => 
                    <div key={i} className={`list-${i+1}`}>
                        {list.listTitle != null && list.listTitle !== "" ? <h5 className="h5">{list.listTitle}:</h5> : ""}
                        <List bulletPoints={list.bulletPoints} className="" />
                    </div>
                )}
            </div>
        </div>
        )
    )
}


export default BenefitItem