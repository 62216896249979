const Button = ({ label, className, divClass, onClick }) => {
    return (
        <div className={`cta-wrapper ${divClass}`}>
            <div onClick={onClick} className={className}>
                {label}
            </div>
        </div>
    )
}

export default Button