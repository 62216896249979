import React, { useState, useContext } from 'react'
import "../../App.css"
import Button from "../../ui/Button"
import { ApplyNowContext } from '../../context/ApplyNowContext'


// Create a component for the overlay
function DownloadPdfOverlay() {
    const { setShowOverlay } = useContext(ApplyNowContext)
    const [checked1, setChecked1] = useState(false)
    const [checked2, setChecked2] = useState(false)
    const handleChange1 = () => {
        setChecked1(!checked1);
    }
    const handleChange2 = (e) => {
        setChecked2(!checked2);
    }
    return (
      <div className="download-overlay-wrapper">
         <div className="outer">
            <div className="close-button" onClick={() => setShowOverlay(false)}>
                <span></span>
                <span></span>
            </div>
            <div className="container">
                <div className="checkboxes">
                    <div>
                        <div className="radio" onClick={handleChange1}>
                            <div className={checked1 === true ? "inner-radio-active" : ""}></div>
                        </div>
                        <label>
                            I have read the <a className="blue-highlight" href="#contestrules" onClick={() => setShowOverlay(false)}>Entry Form, 
                            Release and Non-Disclosure Agreement</a> and hereby agree to the <a className="blue-highlight" href="#official-contest-rules" onClick={() => setShowOverlay(false)}>Official Contest Rules</a>
                        </label>
                    </div>
                    <div>
                        <div className="radio" onClick={handleChange2}>
                            <div className={checked2 === true ? "inner-radio-active" : ""}></div>
                        </div>
                        <label>
                            I have reviewed and agree to the terms of <a href="https://www.rapp.com/privacy-policy/" target="_blank" rel="noreferrer" className="blue-highlight">RAPP's Privacy Policy</a>
                        </label>
                    </div>
                </div>
                <div className="dbl-cta-wrapper">
                    <Button onClick={() => setShowOverlay(false)} className="header-cta black" divClass="back-button" label="Back" />
                    <div className={`cta-wrapper download-button`}>
                        <a href="RAPP_23_RAPP-00006386_Hlth_Eq_Acltr_APPLICATION.pdf" download="RAPP_SeriesR_Application_And_Entry_Form" className={checked1 && checked2 ? "red-b" : "inactive"}>
                            Download <br className="hide-mb" />Application
                        </a>
                    </div>
                </div>
            </div>
         </div>
      </div>
    );
  }

  export default DownloadPdfOverlay